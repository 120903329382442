import { ThatchButton } from '~/components/shared/ThatchButton'
import { Menu, Flex } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons'
import { Typography } from '../shared/text/Typography';
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { ProfileSummaryType } from '~/endpoints/model'
import { useAnalytics } from '~/context';

export function HireMeButton({ profileDetails }: { profileDetails?: ProfileSummaryType }) {
    const thatchSegment = useAnalytics()

    const didOpenMenu = () => {
        console.log('clicked_hire_me_button')
        thatchSegment.trackMiscEvent('clicked_hire_me_button', {})
    }

    return (
        <Menu shadow='md' width={320} position='bottom-start'>
            <Menu.Target>
                <ThatchButton label={
                    <Flex align="center" gap={4} className='px-2 grow md:px-6'>
                        <Typography variant="button_small" color="appWhite.0">Hire Me</Typography>
                        <IconChevronDown size={20} color="white" />
                    </Flex>
                } color="appSuccess.0" size="medium" onClick={didOpenMenu} sx={{
                    '@media (max-width: 768px)': {
                        flexGrow: 1,
                        paddingLeft: 4,
                        paddingRight: 4,
                        // width: '100%'
                    }
                }} />
            </Menu.Target>
            <Menu.Dropdown className='rounded-lg'>
                <ProfileServicesMenuItems profileDetails={profileDetails} source='hire_me_button'/>
            </Menu.Dropdown>
        </Menu>
    );
}

export function ProfileServicesMenuItems({ profileDetails, source }: { profileDetails?: ProfileSummaryType, source: string }) {
    const thatchSegment = useAnalytics()

    function didClickItem(item: string)  {
        thatchSegment.trackMiscEvent('clicked_profile_services_dropdown_item', {
            item,
            source,
            username: profileDetails?.username,
            token: profileDetails?.uid,
            name: profileDetails?.name,
        })
    }
    return (<>
        {profileDetails?.consultation && !profileDetails.consultation.disabled && (
            <Menu.Item component='a' href={`/seller/services/consultation/@${profileDetails.username}`} target='_blank' className='hover:bg-app-paper hover:no-underline' onClick={() => didClickItem('consultation')}>
                <Flex align="center" gap={12}>
                    <div className='rounded-full bg-app-paper p-1.5'>
                        <SvgIcon type={SvgIconType.CONSULTATION_ICON} width={20} height={20} />
                    </div>
                    <Typography variant="body3" color="appBlack.0">Chat with Me</Typography>
                    <div className='h-1 grow' />
                    <IconChevronRight size={20} color="black" />
                </Flex>
            </Menu.Item>
        )}
        {profileDetails?.itinerary && !profileDetails.itinerary.disabled && (
            <Menu.Item component='a' href={`/seller/services/itinerary/@${profileDetails.username}`} target='_blank' className='hover:bg-app-paper hover:no-underline' onClick={() => didClickItem('itinerary')}>
                <Flex align="center" gap={12}>
                    <div className='rounded-full bg-app-paper p-1.5'>
                        <SvgIcon type={SvgIconType.ITINERARY_ICON} width={20} height={20} />
                    </div>
                    <Typography variant="body3" color="appBlack.0">{"I'll plan your trip"}</Typography>
                    <div className='h-1 grow' />
                    <IconChevronRight size={20} color="black" />
                </Flex>
            </Menu.Item>
        )}
        {profileDetails?.recs && !profileDetails.recs.disabled && (
            <Menu.Item component='a' href={`/seller/services/recs/@${profileDetails.username}`} target='_blank' className='hover:bg-app-paper hover:no-underline' onClick={() => didClickItem('recs')}>
                <Flex align="center" gap={12}>
                    <div className='rounded-full bg-app-paper p-1.5'>
                        <SvgIcon type={SvgIconType.RECS_SERVICE_ICON} width={20} height={20} />
                    </div>
                    <Typography variant="body3" color="appBlack.0">{"I’ll make a list of recs for you "}</Typography>
                    <div className='h-1 grow' />
                    <IconChevronRight size={20} color="black" />
                </Flex>
            </Menu.Item>
        )}
    </>)
}